import {useMutation, useQuery, gql} from "@apollo/client";
import { wrapClick } from "utils";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import * as Yup from "yup";
import { GET_SERVICE_REQUEST, UPGRADE_SERVICE_REQUEST } from "./apollo";
import { LocationGenerics } from "router/location";
import {Modal, UpgradeServiceRequestForm} from "components";

const SEND_VERIFY_CONTACT_CODE = gql`
    mutation SendVerifyContactCode($phoneNumber: String!) {
        sendVerifyContactCode(phoneNumber: $phoneNumber)
    }
`;
const VERIFY_CONTACT_CODE = gql`
    mutation VerifyContactCode($phoneNumber: String!, $passcode: String!) {
        verifyContactCode(phoneNumber: $phoneNumber, passcode: $passcode)
    }
`;



export default function UpgradeServiceRequestContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [upgradeServiceRequest] = useMutation(UPGRADE_SERVICE_REQUEST, {
    refetchQueries: ["GetServiceRequest"],
  });

  const availableCategories = ["Premium","Express"] //.filter((category) => category !== data?.serviceRequest?.category)

  const form = useFormik<{
    category: string;
    isPhoneVerified: boolean;
  }>({
    initialValues: {
      category: "",
      isPhoneVerified: false,
    },
    validationSchema: Yup.object({
      category: Yup.string().oneOf(availableCategories, "Kindly choose a valid category").required("New category is required"),
    }),
    onSubmit: async (values) => {
      if(!values.isPhoneVerified){
        toast(
          JSON.stringify({
            type: "error",
            title: "Please verify the customer phone number",
          })
        );
        return;
      }

      await upgradeServiceRequest({
        variables: {
          ...values,
          id: data?.serviceRequest?._id,
        },
      }).then(({ data }) => {
        if (data?.upgradeServiceRequest?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Request Upgraded Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not upgrade Service Request",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  const [sendVerifyContactCode, { loading: sendVerifyContactLoading }] =
    useMutation(SEND_VERIFY_CONTACT_CODE);
  const [verifyContactCode, { loading: verifyContactLoading }] =
    useMutation(VERIFY_CONTACT_CODE);

  const customerPhoneNumber = data?.serviceRequest?.request?.customer?.representative?.phoneNumber || data?.serviceRequest?.request?.customer?.organization?.phoneNumber
  const verifyContactForm = useFormik<any>({
    initialValues: {
      isCodeSent: false,
      isCodeVerified: false,
      passcode: "",
    },
    onSubmit: async (values) => {
      if (values.isCodeSent) {
        await verifyContactCode({
          variables: {
            phoneNumber: customerPhoneNumber,
            passcode: values.passcode,
          },
        }).then(({ data }) => {
          if (data?.verifyContactCode) {
            toast(
              JSON.stringify({
                type: "success",
                title: "Phone number verified",
              })
            );
            verifyContactForm.setFieldValue("isCodeVerified", true);
            form.setFieldValue("isPhoneVerified", true);
            verifyContactForm.setFieldError("isPhoneVerified", undefined);
          } else {
            toast(
              JSON.stringify({
                type: "error",
                title: "Could not send verification code",
              })
            );
          }
        });
      } else {
        await sendVerifyContactCode({
          variables: {
            phoneNumber: customerPhoneNumber,
          },
        }).then(({ data }) => {
          if (data?.sendVerifyContactCode) {
            toast(
              JSON.stringify({
                type: "success",
                title: "Verification Code Sent",
              })
            );
            verifyContactForm.setFieldValue("isCodeSent", true);
          } else {
            toast(
              JSON.stringify({
                type: "error",
                title: "Could not send verification code",
              })
            );
          }
        });
      }
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size='4xl'
      title='Upgrade Service Request'
      description='Provide the details to upgrade service request category'
      renderActions={() => (
        <>
          {
            Boolean(data?.serviceRequest?.category) &&
            ["Standard"].includes(data?.serviceRequest?.category) &&
            !["ConnectionFeePaid",
              "InstallationInitiated",
              "InstallationCompleted",
              "InstallationFailed",
              "Completed"].includes(data?.serviceRequest?.status) &&
            (
              <button
                type='button'
                disabled={form.isSubmitting || !form.isValid}
                className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                onClick={wrapClick(form.handleSubmit)}
              >
                {form.isSubmitting ? "Upgrading ..." : "Upgrade"}
              </button>
            )
          }
        </>
      )}
    >
      {data?.serviceRequest?._id && (
        <UpgradeServiceRequestForm
          form={form}
          verifyContact={{
            form: verifyContactForm,
            verificationLoading: verifyContactLoading,
            sendVerificationLoading: sendVerifyContactLoading,
          }}
          availableCategories={availableCategories}
          customer={{
            ...data?.serviceRequest?.request,
            status: data?.serviceRequest?.status,
            code: data?.serviceRequest?.code,
          }}
        />
      )}
    </Modal>
  );
}
