import {Avatar, SelectInput, TextInput} from "components/core";
import {FormikProps} from "formik";
import lodash from "lodash";
import {classNames} from "utils";
import _ from "lodash";

type Props = {
  availableCategories: string[]
  customer: any;
  form: FormikProps<any>;
  verifyContact: {
    form: FormikProps<any>;
    verificationLoading: boolean;
    sendVerificationLoading: boolean;
  }
};

const UpgradeServiceRequestForm: React.FC<Props> = ({
  customer,
  availableCategories,
  form,
  verifyContact,
}) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200 ">
      <div>
        <div className="">
          <span className="text-xs font-light">Customer Information</span>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="row-span-3 flex flex-col space-y-3 items-center justify-center">
            <Avatar
              alt={
                customer?.customer?.customerType === "Individual"
                  ? customer?.customer?.representative?.fullName || "N A"
                  : customer?.customer?.organization?.name || "N A"
              }
              src={customer?.customer?.profileImageUrl || ""}
              size="xl"
            />

            <span
              className={classNames(
                "bg-gray-100 text-gray-800",
                customer?.status === "SuperAdmin"
                  ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                  : "",
                customer?.status === "Admin"
                  ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                  : "",
                customer?.status === "Supervisor"
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : "",
                customer?.status === "Reader"
                  ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                  : "",
                customer?.status === "Queued"
                  ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                  : "",
                customer?.status === "Success"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                customer?.status === "Reversed"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                !customer?.status
                  ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1",
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                fill="currentColor"
                className="w-1.5 h-1.5"
              >
                <circle
                  fillRule="evenodd"
                  cx="5"
                  cy="5"
                  r="5"
                  clipRule="evenodd"
                />
              </svg>
              <span>
                {lodash.startCase(customer?.status) || "Active Contract"}
              </span>
            </span>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Service Request Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Ghana Card Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.ghanaCardNumber || "N/A"
                : customer?.customer?.organization
                    ?.organizationRegistrationNumber || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.fullName
                : customer?.customer?.organization?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.phoneNumber || "N/A"
                : customer?.customer?.organization?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.emailAddress || "N/A"
                : customer?.customer?.organization?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <span className="text-xs font-light">Category Information</span>
          <div className="grid grid-cols-2 gap-6 mt-2">
            <div className="">
              <SelectInput
                id="category"
                label="New Category"
                options={[
                  {
                    label: "Select New Category",
                    value: "",
                  },
                  ...availableCategories.map((category) => ({
                    label: lodash.startCase(category),
                    value: category,
                  }))
                ]}
                {...form}
              />
            </div>
            <div className="col-start-1">
              <form onSubmit={verifyContact.form.handleSubmit}>
                {!_.get(verifyContact.form.values, "isCodeVerified") &&
                  _.get(verifyContact.form.values, "isCodeSent") &&
                  (
                    <div className="flex-1 mt-4">
                      <TextInput
                        id='passcode'
                        type='text'
                        placeholder='Verification Code'
                        minLength={6}
                        maxLength={6}
                        {...verifyContact.form}
                      />
                    </div>
                  )}
                <div className='flex justify-end mt-4 items-center gap-x-2.5'>
                  {!_.get(verifyContact.form.values, "isCodeVerified") && (
                    <button
                      type='submit'
                      className='inline-flex justify-center h-full rounded-md bg-primary-600 py-[7px] px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                    >
                      {_.get(verifyContact.form.values, "isCodeSent")
                        ? verifyContact.verificationLoading
                          ? "Verifying..."
                          : "Verify"
                        : verifyContact.sendVerificationLoading
                          ? "Sending..."
                          : "Send OTP"
                      }
                    </button>

                  )}
                  {_.get(verifyContact.form.values, "isCodeSent") &&
                    !_.get(verifyContact.form.values, "isCodeVerified") &&
                    (
                      <button
                        type='submit'
                        className='inline-flex justify-center h-full rounded-md bg-primary-600 py-[7px] px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                      >
                        {verifyContact.sendVerificationLoading ? "Sending..." : "Resend"}
                      </button>
                    )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeServiceRequestForm;
