import { useQuery, useReactiveVar } from "@apollo/client";
import { displayUserName, useTableData, useUrlState, wrapClick } from "utils";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { GET_SERVICE_ORDERS, GET_SERVICE_ORDER_SUMMARY } from "./apollo";
import ViewInspectionServiceOrderContainer from "./view";
import ActionButton, { Action } from "components/buttons/action-button";
import { currentConfigVar } from "apollo/cache/config";
import { LocationGenerics } from "router/location";
import config from "config";
import {
  Avatar,
  CalendarHeader,
  EstimatorPicker,
  HeaderTabs,
  SearchSelectInput,
  Shimmers,
  StatusBadge,
  TableComponent,
} from "components";
import { currentDistrictVar } from "apollo/cache/auth";

const mainTabs = [
  { name: "Pending", href: "Pending", sort: "-createdAt" },
  {
    name: "Assigned",
    href: "Assigned",
    sort: "-assignedAt",
  },
  { name: "In Progress", href: "InProgress", sort: "-startedAt" },
  { name: "Resolved", href: "Resolved", sort: "-resolvedAt" },
  { name: "Disapproved", href: "Disapproved", sort: "-disapprovedAt" },
  { name: "Completed", href: "Completed", sort: "-completedAt" },
];

export const InspectionServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [category, setCategory] = useUrlState("category");
  const [assignee, setAssignee] = useUrlState("assignee");

  const [orderStatus] = useUrlState("orderStatus");
  const currentDistrict = useReactiveVar(currentDistrictVar);

  const filter = useMemo(
    () => ({
      page: Number(searchParams.page || config.constants.page),
      pageSize: Number(searchParams.pageSize || config.constants.pageSize),
      search: searchParams.search ? String(searchParams.search) : undefined,
      searchFields: [
        "code",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
        "accountCode",
        "serviceRequestCode",
      ],
      serviceClasses: ["Residential", "NonResidential"],
      ...(currentDistrict ? { district: currentDistrict } : {}),
      status: searchParams.orderStatus || undefined,
      sort: mainTabs?.find((item) => item.href === searchParams.orderStatus)
        ?.sort ?? "-createdAt",
      category: searchParams.category || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      assignee: searchParams.assignee || undefined,
      dateField: "assignedAt",
    }),
    [searchParams, currentDistrict],
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_SERVICE_ORDERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        refetchSummary?.();
      },
    },
  );

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_SERVICE_ORDER_SUMMARY,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
    },
  );

  const records = useTableData(data ? data : []);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? {} : { search: "" }),
        ...(!old?.orderStatus ? { orderStatus: "Pending" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        id: undefined,
      }),
    });
  }, [searchParams.orderStatus, navigate]);

  useEffect(() => {
    setAssignee(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignee]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <CalendarHeader />
      <HeaderTabs
        mainTabs={mainTabs}
        tabKey={"orderStatus"}
        summary={dataSummary?.summary}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"service orders"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
                {orderStatus !== "Pending" && (
                  <EstimatorPicker
                    id="assignee"
                    setFieldValue={(_: any, value: string) =>
                      setAssignee(value)
                    }
                    values={{ assignee }}
                    labelHidden
                    rawId
                  />
                )}
                <SearchSelectInput
                  id="category"
                  labelHidden={true}
                  options={[
                    { label: { title: "Express" }, value: "Express" },
                    { label: { title: "Premium" }, value: "Premium" },
                    { label: { title: "Standard" }, value: "Standard" },
                  ]}
                  label={""}
                  placeholder="Select Category"
                  setFieldValue={(_: any, value: string) => setCategory(value)}
                  values={{ category }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Request
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Location
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Priority
                </th>
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Result
                  </th>
                )}
                {["Assigned", "InProgress", "Resolved"].includes(
                  orderStatus,
                ) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Assigned | ETA
                  </th>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Disapproved
                  </th>
                )}
                {["Completed"].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Resolution Period
                  </th>
                )}
                {[
                  "Assigned",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Assigner
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Assignee
                    </th>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    {orderStatus === "Disapproved" ? "Disapprover" : "Approver"}
                  </th>
                )}
                {!orderStatus && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Status
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                {["Assigned", "InProgress", "Resolved"].includes(
                  orderStatus,
                ) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}

                {[
                  "Assigned",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.AvatarShimmer />
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.AvatarShimmer />
                    </td>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.AvatarShimmer />
                  </td>
                )}
                {!orderStatus && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.serviceRequestCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.location?.address || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.location?.community || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <StatusBadge
                    status={item?.category}
                    options={{
                      Express: "bg-blue-100 text-blue-800",
                      Premium: "bg-cyan-100 text-cyan-800",
                      Standard: "bg-gray-100 text-gray-800",
                    }}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <StatusBadge
                    status={item?.priority}
                    options={{
                      High: "bg-orange-100 text-orange-800",
                      Low: "bg-gray-100 text-gray-800",
                      Critical: "bg-red-100 text-red-800",
                      Medium: "bg-yellow-100 text-yellow-800",
                    }}
                  />
                </td>

                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <StatusBadge
                      status={item?.result}
                      options={{
                        Failed: "bg-orange-100 text-orange-800",
                        Passed: "bg-green-100 text-green-800",
                      }}
                    />
                  </td>
                )}
                {["Assigned", "InProgress", "Resolved"].includes(
                  orderStatus,
                ) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {item?.estimatedResolutionDate
                        ? moment(item?.estimatedResolutionDate).format(
                            dateFormat,
                          )
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Disapproved"].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className="text-gray-900 dark:text-gray-100">
                      <span className="text-xs mr-2 font-light">from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    {/* TODO: take rejectedAt out when model is modified */}
                    <div className="text-gray-500 dark:text-gray-400">
                      <span className="text-xs mr-2 font-light">to</span>
                      {item?.disapprovedAt || item?.rejectedAt
                        ? moment(
                            item?.disapprovedAt || item?.rejectedAt,
                          ).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className="text-gray-900 dark:text-gray-100">
                      <span className="text-xs mr-2 font-light">from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      <span className="text-xs mr-2 font-light">to</span>
                      {item?.completedAt
                        ? moment(item?.completedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {[
                  "Assigned",
                  "InProgress",
                  "Resolved",
                  "Completed",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            alt={displayUserName(item?.assigner) || "N A"}
                            src={item?.assigner?.profileImageUrl || ""}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-gray-900 dark:text-gray-100">
                            {displayUserName(item?.assigner) || "N A"}
                          </div>
                          <div className="text-gray-500 dark:text-gray-400">
                            {item?.assigner?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            alt={displayUserName(item?.assignee) || "N A"}
                            src={item?.assignee?.profileImageUrl || ""}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-gray-900 dark:text-gray-100">
                            {displayUserName(item?.assignee) || "N A"}
                          </div>
                          <div className="text-gray-500 dark:text-gray-400">
                            {item?.assignee?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                  </>
                )}
                {["Completed", "Disapproved"].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={
                            displayUserName(
                              orderStatus === "Disapproved"
                                ? item?.disapprover
                                : item.approver,
                            ) || "N A"
                          }
                          src={
                            (orderStatus === "Disapproved"
                              ? item?.disapprover?.profileImageUrl
                              : item?.approver?.profileImageUrl) || ""
                          }
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-gray-900 dark:text-gray-100">
                          {displayUserName(
                            orderStatus === "Disapproved"
                              ? item?.disapprover
                              : item.approver,
                          ) || "N A"}
                        </div>
                        <div className="text-gray-500 dark:text-gray-400">
                          {(orderStatus === "Disapproved"
                            ? item?.disapprover?.phoneNumber
                            : item?.approver?.phoneNumber) || "N/A"}
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                {!orderStatus && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <StatusBadge
                      status={item?.status}
                      options={{
                        Failed: "bg-orange-100 text-orange-800",
                        Passed: "bg-green-100 text-green-800",
                      }}
                    />
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {Boolean(searchParams.id?.length) && (
        <>
          <ViewInspectionServiceOrderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "disapprove" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default InspectionServiceOrdersPage;
